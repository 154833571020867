header {
  padding: 20px 0;
  .logo {
    .logo-pic {
      height: 50px;
    }
  }
  .navigation {
    display: flex;
    align-items: center;
  }
  &.management {
    padding: 0;
    .management-header-wrapper {
      display: flex;
      justify-content: space-between;
      .logo-bar {
        display: flex;
        align-items: center;
      }
    }
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

a.link {
  &:visited {
    text-decoration: none;
    color: black;
  }
  text-decoration: none;
  color: black;
}