.main-content {
  padding: 40px 0;
  .row {
    padding: 20px 0;
  }
}
.search {
  padding: 40px 0;
  .search-bar {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  .search-result-metadata {
    .info-row {
      margin: 10px auto;
    }
  }
  .result-item {
    margin-bottom: 10px;
  }
  .remark-row {
    text-indent: 2em;
  }
}

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.info-row {
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-right: 20px;
}

.motivations {
  div.MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
}