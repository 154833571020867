.survey-page {
  padding: 100px 0;
  .progress-bar {
    margin-bottom: 20px;
    .MuiLinearProgress-bar1Buffer {
      background-color: #B5123E !important;
    }
    .MuiLinearProgress-bar2Buffer {
      background-color: #B5123E !important;
      opacity: 0.4;
    }
    .MuiLinearProgress-dashed {
      display: none;
    }
  }
  .question-header {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width:600px) {
      display: none;
    }
    .answer-label {
      display: flex;
      justify-content: space-between;
      .left {
        margin-right: 70px;
      }
    }
  }
  .motivation {
    padding: 20px 0;
    color: #B5123E;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
  }
  .alert-box {
    padding: 20px;
    margin: 20px 0;
    background-color: #ecc4cf;
    color: #B5123E;
    border-radius: 10px;
    text-align: left;
    font-size: 18px;
  }
  .pager {
    margin-top: 30px;
    .pager-wrapper {
      display: flex;
      justify-content: space-between;
      .link-text {
        color: white;
        text-decoration: none;
      }
    }
  }
}