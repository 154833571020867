.question {
  .question-wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width:600px) {
      display: block;
    }
    .answer-label {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      @media screen and (min-width:600px) {
        display: none;
      }
      .left {
        margin-right: 70px;
        @media screen and (max-width:600px) {
          margin-right: 0px;
        }
      }
    }
    
    .question-content {
      font-size: 15px;
      text-align: left;
      padding: 5px 0;
    }
    .question-answer {
      flex-shrink: 0;
      @media screen and (max-width:600px) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}