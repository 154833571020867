footer {
  background-color: #626876;
  margin-top: 50px;
  .footer-wrapper {
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    color: white;
    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ul {
      display: flex;
      @media screen and (max-width:600px) {
        display: block;
      }
    }
    li {
      margin-left: 20px;
      @media screen and (max-width:600px) {
        margin-bottom: 10px;
      }
    }
    a.link {
      text-decoration: none;
      color: white;
    }
  }
}